// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import { Buffer } from 'buffer';
import W3obj from "./web3obj/w3obj";
import UtilObj from "./web3obj/util_obj";

// @ts-ignore
window.Buffer = Buffer;
window.W3obj = W3obj;

var Web3 = require("web3");
// window.EthUtil = require('ethereumjs-util');
const { ethers } = require("ethers")
window.ethers = ethers

window.EthUtil = require('ethereumjs-util')
window.Web3 = Web3;
window.UtilObj = UtilObj;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
